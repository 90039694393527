
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/control-center/[[...route]]",
      function () {
        return require("private-next-pages/control-center/[[...route]].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/control-center/[[...route]]"])
      });
    }
  