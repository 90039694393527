import {useTranslation} from "react-i18next";

import useAuth from "hooks/useAuth";

function useHeadTitle(subTitle: string | undefined) {
  const {t} = useTranslation();
  const {isSecureClient} = useAuth();

  if (isSecureClient) {
    return t("app.title.secureClient");
  }

  const titles: string[] = [t("app.title.xdr")];
  if (typeof subTitle === "string") titles.push(subTitle);

  return titles.join(" - ");
}

export default useHeadTitle;
