import {useEffect} from "react";
import {useRouter} from "next/router";

import useNav from "hooks/useNav";
import useAuth from "hooks/useAuth";

const useIsNavAllowed = () => {
  const router = useRouter();
  const {navTree, isUnauthorized} = useNav();
  const {isSecureClient, user} = useAuth();

  useEffect(() => {
    const path = router.pathname;
    const application = path.split("/")[1];
    const section = navTree.find((item) => item.href.includes(application));
    const subSection = section?.subNavigation?.find((item) =>
      path.includes(item.href),
    );
    const isAdmin = user?.role === "admin";

    if (isSecureClient) {
      if (isAdmin && (section?.xdrOnly || isUnauthorized(subSection?.rbac))) {
        router.push("/client-management/clients");
      } else if (section?.xdrOnly || isUnauthorized(subSection?.rbac)) {
        router.push("/administration/my-account");
      }
    }
  }, [router, isSecureClient, navTree, isUnauthorized, user]);
};

export default useIsNavAllowed;
