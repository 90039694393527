
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import dynamic from "next/dynamic";
import Head from "next/head";
import {useRouter} from "next/router";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import {startDatadogView} from "utils/datadog";
import useIsNavAllowed from "hooks/useIsNavAllowed";
import handleImportError from "utils/handleImportError";
import useHeadTitle from "hooks/useHeadTitle";

/* eslint-disable import/no-unresolved */
const Dashboard = dynamic(
  () => import("dashboard/dashboard").catch(handleImportError),
  {ssr: false},
);

const MitreCoverageMap = dynamic(
  () => import("dashboard/mitre-coverage-map").catch(handleImportError),
  {ssr: false},
);

/* eslint-enable import/no-unresolved */
function ControlCenterPageContent(props) {
  const {query} = useRouter();

  switch (query?.route?.[0]) {
    case "mitre-coverage-map":
      return <MitreCoverageMap {...props} />;
    case "":
    case "dashboard":
    default:
      return <Dashboard {...props} />;
  }
}

export default function ControlCenterPage(props) {
  useIsNavAllowed();
  const {t} = useTranslation();
  const title = useHeadTitle(t("controlCenter.title"));

  useEffect(() => {
    startDatadogView("control-center");
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <link
          rel="icon"
          type="image/svg+xml"
          href="/icons/app/control-center.svg"
        />
      </Head>
      <ControlCenterPageContent {...props} />
    </>
  );
}
